import React, { useState } from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";

const ProgressBar = ({ title, value }) => {
    const [focus, setFocus] = useState(false);
    const visibleChangeHandler = (isVisible) => {
        if (isVisible) {
            if (!focus) {
                setFocus(true);
            }
        }
    };

    return (
        <VisibilitySensor
            offset={{ top: 50 }}
            onChange={(isVisible) => visibleChangeHandler(isVisible)}
        >
            {({ isVisible }) => (
                <div className="progress-charts">
                    {title && <h6 className="heading heading-h6">{title}</h6>}
                </div>
            )}
        </VisibilitySensor>
    );
};

ProgressBar.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number.isRequired,
};

export default ProgressBar;
